import React from 'react'
import { images } from 'config/images'

export const MobileAppSerProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Mobile app development services{' '}
        <span className="heading-red">to build innovative experiences</span>
      </>
    ),
    bannerContent: (
      <>
        In a mobile-first world, customers and workers want more ways to engage
        with your brand. Build apps to meet their needs, faster
      </>
    ),
    BannerAlt: 'Mobile app development services',
    buttonName: 'Get Free Consultation',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for
        <br />
        <span className="highlight-head">Mobile App</span>{' '}
        <span className="highlight-head">Development?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Simform’s mobile app development services allow organizations to
            build innovative and user-friendly applications. Our team of expert
            software engineers and developers craft scalable and reliable mobile
            app solutions to improve your business capabilities. We have powered
            several B2B applications by integrating modern technologies like
            Artificial Intelligence, Machine Learning, AR/VR, and others. <br />
            <br />
            We ensure client success by creating robust and high-quality
            software products in sync with their organizational goals. Our
            mobile app services include tech stack consultations, application
            development, testing, deployments, and after-sales support. <br />
            <br />
            Simform also offers modernization of legacy applications with other
            services like system audits and enhancements to existing apps.
          </>
        ),
      },
      {
        detailsList: [
          'Experts in developing native and cross-platform apps',
          'Market-leading innovations through advanced integrations',
          'Feature customizations and solutions that standout',
          'High standards of coding with expert developers',
          'Dedicated team after-sales support and maintenance',
          'Cloud-based deployments and integrations',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Mobile App</span> Development Offering
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Native App Development Services',
        serviceParagraph: (
          <>
            Native B2B and consumer apps allow you to utilize the inherent power
            of <strong>iOS and Android</strong> platforms with secure and
            performant apps.
          </>
        ),
        serviceIcon: images.ServiceEnterpriseAaplication,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Hybrid Cross-platform App Development Services',
        serviceParagraph: (
          <>
            Utilize the power of React Native, Flutter, and Xamarin to develop{' '}
            <strong>apps for multiple platforms</strong> while sharing the same
            code base.
          </>
        ),
        serviceIcon: images.iconHybrid,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Business Analysis & Consulting Services',
        serviceParagraph: (
          <>
            Whether you are looking to <strong>develop business apps</strong> or
            apps to service customers, our consultants can help you define the
            right tech plan.
          </>
        ),
        serviceIcon: images.dashboard,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Business Apps and API Integration Services',
        serviceParagraph: (
          <>
            <strong>Integrate your mobile app(s)</strong> with services you are
            already using and extend their use cases for your employees and
            users.
          </>
        ),
        serviceIcon: images.iconBusinessApp,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: <>Enterprise Mobile Apps</>,
        serviceParagraph: (
          <>
            <strong>Customized mobile solutions</strong> to drive your
            operations. For example, a CMS to organize and publish content, all
            in a simple screen.
          </>
        ),
        serviceIcon: images.iconEnterprise,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Apps for Internet of Things',
        serviceParagraph: (
          <>
            Unlock the potential of high functioning mobile{' '}
            <strong>apps that interact with hardware</strong>
            /wearables/IoT through Wi-Fi, Bluetooth, NFC, and more.
          </>
        ),
        serviceIcon: images.sappMaintenance,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  serviceofferingsProps: {
    // title: (
    //   <>
    //     Mobile Application Development{' '}
    //     <span className="highlight-head">Offerings</span>
    //   </>
    // ),
    Serviceofferings: [
      {
        Title: 'iOS App Development Services',
        para: (
          <>
            iOS application is known for its enhanced native experience, and
            Simform provides solutions that bring the same feature-rich
            capabilities to your apps. Our team of iOS app developers creates
            reliable applications that meet the intune compliance requirements
            across email, device health, device properties, and system security.
            <br />
            <br />
            We offer iOS mobile application development services across Apple
            devices like iPhone, iPad, and others. Our iOS app development
            services involve,
          </>
        ),
        typeList: [
          {
            para:
              'iOS UI/UX design for a uniform experience across Apple devices.',
          },
          {
            para:
              'Feature customizations and bespoke mobile application development.',
          },
          {
            para: 'Advanced iOS testing with dedicated Quality Assurance team.',
          },
          { para: 'Third-party integrations with custom APIs.' },
          { para: 'Deployments across Apple devices.' },
          { para: 'Optimized delivery through enhanced CI/CD methods.' },
        ],
      },
      {
        Title: 'Android App Development Services',
        para: (
          <>
            Simform provides top-notch Android app development services powered
            through evolutionary architecture and innovations. Our end-to-end
            solutions are highly customizable and cater to a wide range of
            business requirements.
            <br />
            <br />
            So whether you need standalone, native, web-based, or
            database-driven applications, we deliver high-end mobile application
            development services. At Simform, we can help your enterprise meet
            ever-changing market demands through,
          </>
        ),
        typeList: [
          {
            para:
              'Well-defined process flow and intelligent development strategies.',
          },
          {
            para: 'Highly secure apps compliant with major data regulations.',
          },
          {
            para:
              'A dedicated team that works as an extension of your organization.',
          },
          {
            para: 'Rigorous mobile application testing with QA best practices.',
          },
          { para: 'Deployments across Android ecosystem devices.' },
          { para: 'Cloud-based integrations and customized tools.' },
        ],
      },
      {
        Title: 'Hybrid app development',
        para: (
          <>
            Hybrid app development encapsulates web-based services in
            applications that offer native features and capabilities. Hybrid app
            developers at Simform are experienced in creating interactive and
            engaging applications across different platforms.
            <br />
            <br />
            We develop reusable components customized as per your business
            requirements to deploy hybrid apps across native platforms. Our
            hybrid app development services include, but are not limited to,
          </>
        ),
        typeList: [
          {
            para: 'Responsive  UI/UX design for higher user engagement.',
          },
          {
            para:
              'Native features compatible across multiple operating systems.',
          },
          { para: 'Hybrid application prototyping and testing.' },
          { para: 'Streamlined deployments using CI/CD approach.' },
          { para: 'Version updates and feature addition support.' },
          {
            para:
              'Integrations of third-party services as per business requirements.',
          },
        ],
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: (
      <>
        Range of Our <span className="highlight-head">Mobile App </span>{' '}
        Development Services
      </>
    ),
    detailsList: [
      <>
        <strong>Mobile app UI/UX Design -</strong> Our expert designers provide
        responsive and interactive design solutions beyond retentions to offer
        unique UX for higher conversions.
      </>,
      <>
        <strong>Backend development -</strong> A pool of experienced backend
        developers at Simform ensures scalable, secure, and robust backend
        architecture for your applications.
      </>,
      <>
        <strong>QA and Support -</strong> A dedicated quality assurance team
        ensures high-performance mobile products. With our support team, you
        will have instant error resolution for your applications.
      </>,
      <>
        <strong>Mobile app evolution -</strong> Simform enables innovations in
        mobile experience by introducing cutting-edge technologies and expanding
        the capabilities of business operations.
      </>,
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Featured{' '}
        <span className="highlight-head">App Development Projects</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head">Simform</span>
      </>
    ),
    processPara: `With Simform, you can expand mobile capabilities without worrying about losing focus on business activities. We provide enterprise-grade solutions with innovative integrations that allow businesses to achieve better ROI. `,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Unlock new business opportunities with mobile apps',
        workingBenefitsContent: (
          <>
            We help companies identify and unlock opportunities in mobility.
            Apps we develop bring the{' '}
            <strong>power of enterprise solutions</strong> to your workforce
            while delivering consumer-grade user experience.
          </>
        ),
        benefitImgAlt: 'New business with mobile app',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            For 11 years, Simform has been helping companies in Manufacturing,
            Healthcare, Banking, Telecom, Retail, and other industries build
            enterprise mobile apps. Our apps have{' '}
            <strong>helped automate 90%</strong> of operations for a
            transportation company, cut down the maintenance cost for a food
            company by 40%
          </>
        ),
        benefitImgAlt: 'Mobile automation',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We collaborate with you to understand your business needs, identify
            processes that can be streamlined, define the user experience, and
            build a <strong>tech delivery roadmap</strong> that aligns with your
            goals.
          </>
        ),
        benefitImgAlt: 'Tech delivery roadmap',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Bringing the Lean approach to apps',
        workingBenefitsContent: (
          <>
            Our Product team takes your ideas and turns them into high output
            apps with <strong>high ROI and retention rates</strong>. We use user
            research, user testing, and design thinking to bring consumerization
            to your IT.
          </>
        ),
        benefitImgAlt: 'Lean mobile app development',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Incorporate security best practices',
        workingBenefitsContent: (
          <>
            Mobile app testing best practices reduce the{' '}
            <strong>security risks, tests potential vulnerabilities</strong> and
            incorporates data encryption. With all the steps we take, you can be
            confident that mobile apps will always be secure.
          </>
        ),
        benefitImgAlt: 'Secure mobile app',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What Our <span className="highlight-head">Customers Say</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  developSoftAgileProps: {
    heading: (
      <>
        How We Develop
        <br />
        <span className="highlight-head">Engaging App Experiences</span>
      </>
    ),
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Sprint planning',
        workFlowParagraph:
          'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Sprint',
        workFlowTitle: 'Tech architecture',
        workFlowParagraph:
          'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Stand-ups & weekly demos',
        workFlowParagraph:
          'Stand ups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Code reviews',
        workFlowParagraph:
          'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Transportation',
        contentHeading: 'Fleet management app',
        contentPara:
          'Fleet Management Software to control the chaos, improve efficiency, productivity and reduce overall transportation cost.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile CRM app',
        contentPara:
          'Mobile CRM is operational, analytical and collaborative software that allows bank managers to manage their banking clients on the go. ',
        contentSingleProps: [
          {
            singleblkImg: images.mobilecrm1,
            singleblkImg2x: images.mobilecrm12x,
          },
          {
            singleblkImg: images.mobilecrm2,
            singleblkImg2x: images.mobilecrm22x,
          },
          {
            singleblkImg: images.mobilecrm3,
            singleblkImg2x: images.mobilecrm32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'Hospital management app',
        contentPara:
          'An integrated mobile system for managing hospital operations such as medical, administrative, financial, legal and other corresponding services. ',
        contentSingleProps: [
          {
            singleblkImg: images.hospital1,
            singleblkImg2x: images.hospital12x,
          },
          {
            singleblkImg: images.hospital2,
            singleblkImg2x: images.hospital22x,
          },
          {
            singleblkImg: images.hospital3,
            singleblkImg2x: images.hospital32x,
          },
        ],
      },
      {
        tabName: 'Tourism ',
        contentHeading: 'Mobile POS for restaurant',
        contentPara:
          'A mobile app for restauranteurs to speed up their check out process, accept credit card payments, track sales, inventory and manage other business functions.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilepos1,
            singleblkImg2x: images.mobilepos12x,
          },
          {
            singleblkImg: images.mobilepos2,
            singleblkImg2x: images.mobilepos22x,
          },
          {
            singleblkImg: images.mobilepos3,
            singleblkImg2x: images.mobilepos32x,
          },
        ],
      },
      {
        tabName: 'Consumer',
        contentHeading: 'Video conferencing app',
        contentPara:
          'An API-driven instant messaging and video conference app for higher connectivity with real-time data communication built over serverless technology.',
        contentSingleProps: [
          {
            singleblkImg: images.videoapp1,
            singleblkImg2x: images.videoapp12x,
          },
          {
            singleblkImg: images.videoapp2,
            singleblkImg2x: images.videoapp22x,
          },
          {
            singleblkImg: images.videoapp3,
            singleblkImg2x: images.videoapp32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'E-learning application',
        contentPara:
          'e-Learning Mobile App with user-centered design standards for content delivery and assessment process with high security, accessibility, and data maintenance.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail',
        contentHeading: 'Inventory management app',
        contentPara:
          'Mobile App for supply chain management for multiple locations, safety stock, re-order points, cycle counts, demand and distribution requirements planning.',
        contentSingleProps: [
          {
            singleblkImg: images.ecommerce1,
            singleblkImg2x: images.ecommerce12x,
          },
          {
            singleblkImg: images.ecommerce2,
            singleblkImg2x: images.ecommerce22x,
          },
          {
            singleblkImg: images.ecommerce3,
            singleblkImg2x: images.ecommerce32x,
          },
        ],
      },
      {
        tabName: 'Sports',
        contentHeading: 'Stadium management portal',
        contentPara:
          'Indoor Stadium Management Platform for storage, location, and retrieval of information, ticketing system, crowd control system, and facility management system.',
        contentSingleProps: [
          {
            singleblkImg: images.stadium1,
            singleblkImg2x: images.stadium12x,
          },
          {
            singleblkImg: images.stadium2,
            singleblkImg2x: images.stadium22x,
          },
          {
            singleblkImg: images.stadium3,
            singleblkImg2x: images.stadium32x,
          },
        ],
      },
    ],
  },
  techstacksProps: {
    heading: 'Tech Stacks',
    tabContents: [
      {
        tabName: 'Backend',
        tabimages: [
          {
            src: images.iconNodeJS,
            alt: 'Node.js',
          },
          {
            src: images.iconNETCore,
            alt: '.Net Core',
          },
          {
            src: images.iconAspNET,
            alt: 'asp.Net (C#)',
          },
          {
            src: images.iconPHP,
            alt: 'PHP',
          },
          {
            src: images.iconPython,
            alt: 'Python',
          },
          {
            src: images.iconJava,
            alt: 'Java',
          },
          {
            src: images.iconRubyOnRails,
            alt: 'Ruby On Rails',
          },
          {
            src: images.iconGo,
            alt: 'Go',
          },
        ],
      },
      {
        tabName: 'Frontend',
        tabimages: [
          {
            src: images.iconElectron,
            alt: 'Electron',
          },
          {
            src: images.iconNative,
            alt: 'Native',
          },
          {
            src: images.iconTypeScript,
            alt: 'TypeScript',
          },
          {
            src: images.iconXamarin,
            alt: 'Xamarin',
          },
          {
            src: images.iconVue,
            alt: 'Vue',
          },
          {
            src: images.iconReact,
            alt: 'React',
          },
          {
            src: images.iconReactNative,
            alt: 'ReactNative',
          },
          {
            src: images.iconWPF,
            alt: 'WPF',
          },
          {
            src: images.iconAdnroid,
            alt: 'Adnroid',
          },
          {
            src: images.iconJavaScript,
            alt: 'JavaScript',
          },
          {
            src: images.iconAngularJS,
            alt: 'AngularJS',
          },
          {
            src: images.iconiOS,
            alt: 'iOS',
          },
          {
            src: images.iconUnity,
            alt: 'Unity',
          },
        ],
      },
      {
        tabName: 'Databases',
        tabimages: [
          {
            src: images.iconGroup32,
            alt: 'Group32',
          },
          {
            src: images.iconCloudera,
            alt: 'Cloudera',
          },
          {
            src: images.iconGroup42,
            alt: 'Group42',
          },
          {
            src: images.iconMySQL,
            alt: 'MySQL',
          },
          {
            src: images.iconRethinkDB,
            alt: 'RethinkDB',
          },
          {
            src: images.iconFirestore,
            alt: 'Firestore',
          },
          {
            src: images.iconSQLServer,
            alt: 'SQLServer',
          },
          {
            src: images.iconPostageSQL,
            alt: 'PostageSQL',
          },
          {
            src: images.iconOracle,
            alt: 'Oracle',
          },
          {
            src: images.iconhbase,
            alt: 'hbase',
          },
          {
            src: images.iconMAPR,
            alt: 'MAPR',
          },
          {
            src: images.iconHortonworks,
            alt: 'Hortonworks',
          },
          {
            src: images.iconHadoop,
            alt: 'Hadoop',
          },
          {
            src: images.iconFirebase,
            alt: 'Firebase',
          },
          {
            src: images.iconCouchbase,
            alt: 'Couchbase',
          },
          {
            src: images.iconNeptune,
            alt: 'Neptune',
          },
          {
            src: images.iconRedis,
            alt: 'Redis',
          },
          {
            src: images.iconMongoDB,
            alt: 'MongoDB',
          },
          {
            src: images.iconcassandra,
            alt: 'cassandra',
          },
          {
            src: images.iconElasticSearch,
            alt: 'ElasticSearch',
          },
        ],
      },
      {
        tabName: 'CMS',
        tabimages: [
          {
            src: images.iconSuccessfactors,
            alt: 'Successfactors',
          },
          {
            src: images.iconWordpress,
            alt: 'Wordpress',
          },
          {
            src: images.iconMagento,
            alt: 'Magento',
          },
          {
            src: images.iconCanvas,
            alt: 'Canvas',
          },
          {
            src: images.iconLitmus,
            alt: 'Litmus',
          },
          {
            src: images.iconLiferay,
            alt: 'Liferay',
          },
          {
            src: images.iconShopify,
            alt: 'Shopify',
          },
          {
            src: images.iconDocebo,
            alt: 'Docebo',
          },
          {
            src: images.iconMoodle,
            alt: 'Moodle',
          },
          {
            src: images.iconUmbraco,
            alt: 'Umbraco',
          },
          {
            src: images.iconSitecore,
            alt: 'Sitecore',
          },
          {
            src: images.iconTotara,
            alt: 'Totara',
          },
          {
            src: images.iconAdapt,
            alt: 'Adapt',
          },
        ],
      },
      {
        tabName: 'Cloud',
        tabimages: [
          {
            src: images.iconGooglecloud,
            alt: 'Google cloud',
          },
          {
            src: images.icontwilio,
            alt: 'twilio',
          },
          {
            src: images.iconAlgolia,
            alt: 'Algolia',
          },
          {
            src: images.iconTalend,
            alt: 'Talend',
          },
          {
            src: images.iconSpluck,
            alt: 'Spluck',
          },
          {
            src: images.iconAWS,
            alt: 'AWS',
          },
          {
            src: images.iconAlfresco,
            alt: 'Alfresco',
          },
          {
            src: images.iconStripe,
            alt: 'Stripe',
          },
          {
            src: images.iconSharePoint,
            alt: 'SharePoint',
          },
          {
            src: images.iconApigee,
            alt: 'Apigee',
          },
          {
            src: images.iconPivotal,
            alt: 'Pivotal',
          },
          {
            src: images.iconSalesforce,
            alt: 'Salesforce',
          },
          {
            src: images.iconDocker,
            alt: 'Docker',
          },
          {
            src: images.iconMulesoft,
            alt: 'Mulesoft',
          },
          {
            src: images.iconNexmo,
            alt: 'Nexmo',
          },
          {
            src: images.iconkubernetes,
            alt: 'kubernetes',
          },
          {
            src: images.iconAzure,
            alt: 'Azure',
          },
        ],
      },
      {
        tabName: 'Testing',
        tabimages: [
          {
            src: images.iconSoupUI,
            alt: 'SoupUI',
          },
          {
            src: images.iconMochas,
            alt: 'Mochas',
          },
          {
            src: images.iconAppium,
            alt: 'Appium',
          },
          {
            src: images.iconHoverfly,
            alt: 'Hoverfly',
          },
          {
            src: images.iconJMeter,
            alt: 'JMeter',
          },
          {
            src: images.iconKatalon,
            alt: 'Katalon',
          },
          {
            src: images.iconSelenium,
            alt: 'Selenium',
          },
          {
            src: images.iconGatling,
            alt: 'Gatling',
          },
          {
            src: images.iconSaucelabs,
            alt: 'Saucelabs',
          },
        ],
      },
      {
        tabName: 'DevOps',
        tabimages: [
          {
            src: images.iconTravisCI,
            alt: 'TravisCI',
          },
          {
            src: images.iconCircleCI,
            alt: 'CircleCI',
          },
          {
            src: images.iconCodeship,
            alt: 'Codeship',
          },
          {
            src: images.icongradle,
            alt: 'gradle',
          },
          {
            src: images.iconJenkins,
            alt: 'Jenkins',
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'How do you design mobile apps?',
        faqPara: (
          <>
            Even the most visually amazing mobile app can and will fail to
            perform well on the app store if it’s not intuitive and lacks
            usability.
            <br /> <br />
            As a user, you would want to open an app and feel like you already
            intuitively know everything — where to click next, what actions to
            perform, how to jump to a particular place, etc. The second you get
            stuck, you give up and go try another app. Sounds familiar?
            <br /> <br />
            So what do we do to make sure this doesn’t happen to the potential
            users you just spent so much time researching? We do UX which is
            included in our mobile app development services.
            <br /> <br />
            UX is the process of creating products that provide meaningful and
            relevant experiences to users. This involves the design of the
            entire process of acquiring and integrating the product, including
            aspects of branding, design, usability, and function — The
            Interaction Design Foundation.
          </>
        ),
      },
      {
        faqTitle: 'How do you choose the mobile app architecture?',
        faqPara: (
          <>
            These are the things we keep in mind when choosing mobile app
            architecture: Portability, Maintainability, Manageability,
            Reusability, Testability, Security, Performance
            <br /> <br />
            Also, while making architectural decisions, we need to keep these in
            mind as well.
            <br />
            <br />
            1. Components of the app should be nicely organized and decoupled.
            <br /> 2. Architecture should speak about the business domain of the
            project. Is it a food delivery application Or maybe one for
            financial institutions?
            <br />
            3. Scalability: How easy it is to add more features? Having an
            elegant solution might save us a lot of time and money in the
            future.
            <br />
            4. How the architecture fits the requirements of the business
            domain. Is it a heavy data-driven app? Does it have a lot of forms
            that require user input? What is the complexity of the app that we
            are going to build? Is it a “5 screens” app or “20 screens” app?
            <br />
            5. Will the team be able to work independently on features, without
            blocking themselves?
            <br />
            6. What are the key components that we want to test?
            <br />
            7. What is the deadline and budget for the project? What is the
            trade-off that we must do in terms of quality — time of delivery?
            <br />
            8. Consider all network scenarios.
          </>
        ),
      },
      {
        faqTitle: 'How much does mobile app development cost?',
        faqPara: (
          <>
            The cost of Mobile App development depends on many factors such as:
            <br /> <br />
            - Development platform - native, hybrid, cross-platform
            <br /> - App categories - e-commerce, Gaming, Entertainment,
            On-Demand Delivery
            <br />
            - App Complexities - number of screens, limited data, need for API
            connection, admin console, user profiles <br />
            - Resource location - Orlando or other cities in the USA.
            <br /> <br />
            After having established these factors, we create a detailed
            description that includes user journeys, tech stack, project
            investment, and timeline. Contact us with your requirement to get
            the mobile app development cost.
          </>
        ),
      },

      {
        faqTitle: 'How long does it take to build a mobile application?',
        faqPara: (
          <>
            The exact time to build a mobile application depends on its
            features, the screen sizes, and the frameworks used. There is a huge
            process involved in developing mobile apps. But it normally takes 2
            months to around 4–5 months depending on developers, functionalities
            of the app, platform, and much more.
          </>
        ),
      },

      {
        faqTitle:
          'Do you provide maintenance and support after developing a Mobile App?',
        faqPara: (
          <>
            Yes, we provide free support and maintenance during development.
            Once the project is completed and delivered to you, we provide paid
            support at very nominal rates. Also, we help you to launch your
            mobile app on the App Store or Google Play without any extra cost.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/mobile-app-performance/',
        featurecardTitle: 'How to Improve your Mobile App’s Performance?',
      },
      {
        blogLink: 'https://www.simform.com/blog/react-native-vs-ionic/',
        featurecardTitle:
          'React Native vs Ionic: Which Framework is best and Why?',
      },
      {
        blogLink: 'https://www.simform.com/blog/flutter-vs-ionic/',
        featurecardTitle:
          'Flutter vs Ionic: Which one should you choose for your next project?',
      },
    ],
  },
}
